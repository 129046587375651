import React, { Component } from 'react';
import Joi from 'joi-browser';
import Input from 'src/components/common/input';
import Button from 'src/components/common/button';

class Form extends Component {
    state = {
        data: {},
        errors: {}
    }

    validate = () => {

        const options = { abortEarly: false };
        const { error } = Joi.validate(this.state.data, this.schema, options);

        if (!error) return null; // response also used to disable the button

        const errors = {};

        for (let item of error.details) {
            errors[item.path] = item.message;
        }

        return errors;

    }

    validateProperty = ({ name, value }) => {

        const obj = { [name]: value };

        const schema = { [name]: this.schema[name] };
        const { error } = Joi.validate(obj, schema);

        if (!error) return null;

        return error.details[0].message;

    }

    handleSubmit = e => {

        e.preventDefault();

        const errors = this.validate();

        this.setState({ errors: errors || {} });

        this.doSubmit();
    }


    handleChange = ({ currentTarget: input }) => {

        const errors = { ...this.state.errors };

        const errorMessage = this.validateProperty(input);

        if (errorMessage) {
            errors[input.name] = errorMessage
        } else {
            delete errors[input.name];
        }

        const data = { ...this.state.data };

        data[input.name] = input.value;

        this.setState({ data, errors });
    }

    renderButton(label) {

        return (
            <Button
                disabled={this.validate()}
                buttonLabel={label}
                classList="btn-primary"
            />
        )
    }

    renderInput(name, label, type = 'text') {

        const { data, errors } = this.state;

        return (
            <Input
                type={type}
                name={name}
                label={label}
                value={data[name]}
                onChange={this.handleChange}
                error={errors[name]}
            />
        );
    }

}

export default Form;